
import { mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
export default {
    name: 'Theme3DesktopSlider',
    mixins: [cms, playGames],
    data() {
        return {
            slider: 0,
            sliderItemHeight: 60,
            sliders: [1, 2, 3, 4],
            sliderWidth: 733,
            sliderHeight: 250,
        }
    },
    computed: {
        ...mapGetters({
            mobileSlider: 'cms/mobileSlider',
        }),
    },
    methods: {
        getSliderImageWidth() {
            if (process.browser) {
                this.sliderWidth = window.innerWidth
                if (~~this.sliderWidth > 960) {
                    this.sliderWidth = 900
                }
                const height = Math.floor((this.sliderWidth * 450) / 1320)
                this.sliderHeight = height
            }
        },
    },
}
