import { render, staticRenderFns } from "./FloatingIcon1.vue?vue&type=template&id=dd9889c0&scoped=true"
import script from "./FloatingIcon1.vue?vue&type=script&lang=js"
export * from "./FloatingIcon1.vue?vue&type=script&lang=js"
import style0 from "./FloatingIcon1.vue?vue&type=style&index=0&id=dd9889c0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd9889c0",
  null
  
)

export default component.exports