
import games from '~/mixins/common/pages/games'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
export default {
    name: 'GameMenu',
    mixins: [games, cms, playGames],

    methods: {
        getImageUri(icon) {
            if (!icon) return null
            const menubarIcon = ~~icon
            let imagePath = 'theme-3/mobile/games/v2/'
            // menubar_icon
            // 5 = slot
            // 3 = live casino
            // 8 = Virtual Sport
            // 1 = animal
            // 2 = finishing
            // 4 = Lottery
            // 6 = Sport
            // 7 = Table
            // 9 = Others
            if (menubarIcon === 1) imagePath += 'animal.png'
            else if (menubarIcon === 2) imagePath += 'fishing.png'
            else if (menubarIcon === 3) imagePath += 'casino.png'
            else if (menubarIcon === 4) imagePath += 'lottery.png'
            else if (menubarIcon === 5) imagePath += 'slot.png'
            else if (menubarIcon === 6) imagePath += 'sport.png'
            else if (menubarIcon === 7) imagePath += 'table-games.png'
            else if (menubarIcon === 8) imagePath += 'virtual.png'
            else if (menubarIcon === 9) imagePath += 'other-games.png'

            return imagePath
        },
    },
}
