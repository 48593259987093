import { mapState } from 'vuex'
import playGames from '../play-games'
export default {
	mixins: [playGames],
	data() {
		return {
			gameMenuHeight: 200,
			gameList: [],
			gameIndex: 0,
			isScrollDown: false,
			menuBlockType: 1, // 1 is a 2-3-4 Images on a row Grid Style, 2 is one image for one row
			scrollHandler: null,
		}
	},
	computed: {
		...mapState({
			menubars: (state) => state.cms.menus.menus
		}),
		blockType() {
			return this.menuBlockType
		},
	},
	watch: {
		menubars(value) {
			if (value) {
				if (value.length > 0) {
					this.onChangeGameMenu(this.gameIndex)
				}
				const games = this.getVisibleItems(value)

				if (games.length > 0) {
					const items = games[0].items.map((data) => {
						return {
							...data,
							component_code: games[0].component_code,
						}
					})
					this.gameList = items
				}
			}
		},
	},
	beforeDestroy() {
		if (this.scrollHandler) {
			window.removeEventListener('scroll', this.scrollHandler)
		}
	},
	mounted() {
		this.onResize()
		this.setOriginalMenu()
		if (this.menubars && this.menubars.length > 0) {
			this.onChangeGameMenu(this.gameIndex)
		}
		const element = document.getElementById('home-slider-theme')
		function getElementPosition() {
			const rect = element.getBoundingClientRect()
			return rect.top + window.scrollY
		}
		const vm = this
		this.scrollHandler = function () {
			const position = getElementPosition()
			vm.isScrollDown = position > 230
		}
		window.addEventListener('scroll', this.scrollHandler)
	},
	methods: {
		onResize() {
			if (process.browser) {
				const WINDOW_WIDTH = window.innerWidth
				let IMAGE_SLIDER_HEIGHT = 298
				if (WINDOW_WIDTH < 960) {
					IMAGE_SLIDER_HEIGHT = (WINDOW_WIDTH * 450) / 1320
				}

				const HEADER_HEIGHT = 82
				const ANNOUNCEMENT_HEIGHT = 36
				const SLIDER_PADDING = 12 * 2
				const CUSTOM_FOOTER_HEIGHT = 80

				const REST_OF_GAME_HEIGHT =
					~~HEADER_HEIGHT +
					~~ANNOUNCEMENT_HEIGHT +
					~~SLIDER_PADDING +
					~~IMAGE_SLIDER_HEIGHT +
					~~CUSTOM_FOOTER_HEIGHT

				this.gameMenuHeight = window.innerHeight - REST_OF_GAME_HEIGHT
			}
		},
		setOriginalMenu() {
			// if you play game, and come back to the page, the active menu games will be active
			const componentId = this.$cookie.get('component')
			let menubarIndex = 0
			if (componentId) {
				let menuIndex = 0
				for (const menubar of this.menubars) {
					if (menubar._id === componentId) {
						menubarIndex = menuIndex
						break
					}
					menuIndex = menuIndex + 1
				}
			} else {
				menubarIndex = null
			}
			this.gameIndex = menubarIndex
		},
		onChangeGameMenu(id) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			let index = id
			if (id === null) {
				index = 0
			}
			this.gameIndex = index
			const items = this.getVisibleItems(this.menubars)[
				index
			]?.items?.map((data) => {
				return {
					...data,
					component_code: this.getVisibleItems(this.menubars)[index]
						.component_code,
				}
			})
			this.menuBlockType = this.getVisibleItems(this.menubars)[
				index
			]?.block_type

			this.gameList = items
		},
		doPlayGame(item) {
			this.$cookie.set('component', item.component_content_id)
			this.openNewBrowserTab(this.getLocaleLang(item.link) || '', item)
		},
	}
}