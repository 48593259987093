
import { mapGetters } from 'vuex'
import indexMixin from '~/mixins/common/index'
export default {
    name: 'Index',
    mixins: [indexMixin],
    computed: {
        ...mapGetters({
            getSequenceAbleComponent: 'cms/getSequenceAbleComponent',
        }),
        sequenceComponentCmsForDesktop() {
            return [
                {
                    name: 'Theme3DesktopHomeSlider',
                    code: 'SliderContentDesktop',
                },
                {
                    name: 'Theme3DesktopFeaturedContent1',
                    code: 'FeaturedContent1Desktop',
                },
                {
                    name: 'Theme3DesktopPromotionContent',
                    code: 'PromotionContentDesktop',
                },
                {
                    name: 'Theme3DesktopFeaturedContent2',
                    code: 'FeaturedContent2Desktop',
                },
            ]
        },
        dynamicSequenceComponentForDesktop() {
            const findMatchComponent = (componentCode) => {
                return this.getSequenceAbleComponent('desktop')?.find(
                    (el) => el.component_code === componentCode
                )?.sequence_desktop
            }
            return this.sequenceComponentCmsForDesktop
                .map((el) => {
                    return {
                        ...el,
                        sequence: findMatchComponent(el.code),
                    }
                })
                .sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence))
        },
    },
}
